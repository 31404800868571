/* 
// Uncomment this if craco ever stops working or gives bugs; could revert package.json back to using react-scripts for debugging purposes.
@import url("https://unpkg.com/@tailwindcss/typography@0.4.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css");
*/
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Montserrat&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Permanent+Marker&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.cursive {
  font-family: 'Amatic SC', cursive;
  font-weight: 700;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}